<script>
import { mapActions } from 'vuex'
import { required, requiredIf, minLength } from 'vuelidate/lib/validators'
export default {
  name: 'ManagementSurveyEditQuestions',
  components: {
    Action: () => import('@/components/general/Action'),
    ActionBar: () => import('@/components/general/ActionBar'),
    ContentHeader: () => import('@/components/general/ContentHeader'),
    Pagination: () => import('@/components/general/Pagination'),
    ManagementSurveyEditQuestionWidget: () => import('@/modules/Management/components/ManagementSurveyEditQuestionWidget')
  },

  data () {
    return {
      pagination: {
        page: 2,
        lastPage: 2
      },

      formData: {
        id: null,
        surveyQuestion: [],
        questionTypes: [
          {
            value: 'checkbox',
            text: this.$t('management.survey.edit:question.multiple')
          },
          {
            value: 'text',
            text: this.$t('management.survey.edit:question.open')
          },
          {
            value: 'slide',
            text: this.$t('management.survey.edit:question.scale')
          },
          {
            value: 'radio',
            text: this.$t('management.survey.edit:question.single')
          }
        ],

        mandatoryOptions: [
          {
            label: this.$t('global:yes'),
            value: true
          },
          {
            label: this.$t('global:no'),
            value: false
          }
        ]
      }
    }
  },

  validations: {
    formData: {
      surveyQuestion: {
        $each: {
          title: { required },
          type: { required },
          data: {
            min: { required },
            max: { required }
          },

          surveyQuestionOption: {
            required: requiredIf(function (item) {
              return [
                'checkbox',
                'radio'
              ].indexOf(item.type) > -1
            }),

            $each: {
              name: {
                required
              }
            },

            minLength: minLength(1)
          }
        },

        required
      }
    }
  },

  computed: {
    isEditing () {
      return this.$route.params.id
    },

    hasNonNpsQuestions () {
      return this.nonNpsQuestions && this.nonNpsQuestions.length > 0
    },

    nonNpsQuestions () {
      return this.formData.surveyQuestion.filter(item => ![
        'nps',
        'nps_motivo'
      ].includes(item.name))
    },

    hasNpsQuestions () {
      return this.npsQuestions && this.npsQuestions.length > 0
    },

    npsQuestions () {
      return this.formData.surveyQuestion.filter(item => [
        'nps',
        'nps_motivo'
      ].includes(item.name))
    }
  },

  watch: {
    'question.data.max' () {
      if (this.question.data.min >= this.question.data.max) {
        this.question.data.min = this.question.data.max - 1
      }
    }
  },

  created () {
    this.attemptGetSurvey(this.$route.params.id).then((survey) => {
      if (survey.data.surveyAnswered) {
        this.setFeedback({ message: this.$t('management.survey:feedback.linked.error') })
        this.$router.push({ name: 'management.survey' })
      }

      const mandatoryOptions = this.formData.mandatoryOptions
      const questionTypes = this.formData.questionTypes

      this.formData = survey.data
      this.formData.mandatoryOptions = mandatoryOptions
      this.formData.questionTypes = questionTypes

      this.formData.surveyQuestion = this.formData.surveyQuestion.map(item => {
        item.required = item.required === 1

        item.data = {
          min: item.data.min ? item.data.min : 0,
          max: item.data.max ? item.data.max : 5
        }

        return item
      })
    }).catch(() => {
      this.setFeedback({ message: this.$t('management.survey:feedback.not.found.error') })
      this.$router.push({ name: 'management.survey' })
    })
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptGetSurvey',
      'attemptUpdateSurvey'
    ]),

    prevPage () {
      this.$router.push({
        name: 'management.survey.edit',
        params: { id: this.$route.params.id }
      })
    },

    leave () {
      this.modalConfirm = false

      this.$nextTick(() => {
        this.$router.push({ name: 'management.survey' })
      })
    },

    treatQuestions (formData) {
      formData.surveyQuestion = formData.surveyQuestion.map((item, index) => {
        if (item.data) {
          item.data.min = parseInt(item.data.min)
          item.data.max = parseInt(item.data.max)
        }

        if (!item.name) item.name = item.title
        item.ordering = index
        item.required = item.required ? 1 : 0

        item.options = item.surveyQuestionOption.map((answer, answerIndex) => {
          answer.ordering = answerIndex
          answer.value = answer.name.replace(/ +/g, '-')

          return answer
        })

        return JSON.stringify(item)
      })

      return formData
    },

    submitUpdate () {
      this.setFetching(true)
      let stringifiedQuestionsData = { ...this.formData }

      stringifiedQuestionsData = this.treatQuestions(stringifiedQuestionsData)

      this.attemptUpdateSurvey(stringifiedQuestionsData).then(() => {
        this.setFeedback({ message: this.$t('management.survey:feedback.updated.success') })
      }).catch(() => {
        this.setFeedback({ message: this.$t('management.survey:feedback.updated.error') })
      }).finally(() => {
        this.$router.push({ name: 'management.survey' })
        this.setFetching(false)
      })
    },

    submit () {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.submitUpdate()
      } else {
        this.setFeedback({ message: this.$t('management.survey:feedback.validation.error') })
      }
    },

    scrollToElem (elem, offset = 0) {
      const top = elem ? (elem.offsetTop + offset) : 0

      setTimeout(() => {
        window.scroll({
          top: top,
          behavior: 'smooth'
        })
      }, 100)
    },

    addQuestion () {
      const newItemIndex = this.formData.surveyQuestion.length

      this.formData.surveyQuestion.splice(newItemIndex, 0, {
        title: null,
        type: 'checkbox',
        required: true,
        surveyQuestionOption: [],
        data: {
          min: 0,
          max: 5
        }
      })

      // Scrolls to new added question
      let scrollableElem = null

      if (this.$refs.nonNpsQuestions) {
        const newNonNpsIndex = this.$refs.nonNpsQuestions.querySelectorAll('li.question').length - 1

        scrollableElem = this.$refs.nonNpsQuestions
          ? this.$refs.nonNpsQuestions.querySelectorAll('li.question')[newNonNpsIndex]
          : null
      } else {
        scrollableElem = this.$refs.formQuestions
      }

      this.scrollToElem(scrollableElem, 240)
    },

    removeQuestion (index) {
      this.formData.surveyQuestion.splice(index, 1)
    },

    updateQuestion (question) {
      this.$set(this.formData.surveyQuestion, question.index, question.question)
    },

    checkInitValue (index) {
      if (this.formData.surveyQuestion[index].data.min >= this.formData.surveyQuestion[index].data.max) {
        this.formData.surveyQuestion[index].data.min = this.formData.surveyQuestion[index].data.max - 1
      }
    }
  }
}
</script>

<template>
  <div class="main-content management-survey-questions-create">
    <ContentHeader
      v-if="formData.name"
      :title="isEditing ? formData.name : $t('management.survey.edit:header.title')"
      dark-theme
      fixed
    >
      <Action
        slot="back"
        type="button"
        :text="$t('global:back.surveys')"
        class="btn-back"
        icon="keyboard_backspace"
        @click="leave()"
      />
      <ActionBar slot="actionbar" />
      <template slot="buttons">
        <Action
          :text="$t('global:form.save')"
          type="button"
          dark
          flat
          @click="submit()"
        />
      </template>
    </ContentHeader>
    <div class="main-content-inner">
      <div class="center">
        <div class="management-survey-create-header">
          <h2 class="management-survey-create-title">
            {{ $t('global:form.step', {'num': 2}) }}
          </h2>
          <p
            class="management-survey-create-description"
            v-html="$t('management.survey.edit:header.description.2')"
          />
        </div>
        <form
          ref="formQuestions"
          class="form"
          @submit.prevent="submit"
        >
          <div
            v-if="hasNpsQuestions"
            class="questions-nps"
          >
            <ul
              class="questions"
            >
              <li
                v-for="(question, npsQuestionIndex) in npsQuestions"
                :key="npsQuestionIndex"
                class="question"
              >
                <ManagementSurveyEditQuestionWidget
                  :question="question"
                  :question-index="npsQuestionIndex"
                  :validations="$v.formData.surveyQuestion.$each[npsQuestionIndex]"
                  @updateQuestion="updateQuestion"
                  @removeQuestion="removeQuestion"
                  @checkInitValue="checkInitValue"
                />
              </li>
            </ul>
          </div>
          <ul
            v-if="hasNonNpsQuestions"
            ref="nonNpsQuestions"
            class="questions"
          >
            <li
              v-for="(question, nonNpsQuestionIndex) in nonNpsQuestions"
              :key="nonNpsQuestionIndex + npsQuestions.length"
              class="question"
            >
              <ManagementSurveyEditQuestionWidget
                :question="question"
                :question-index="npsQuestions.length + nonNpsQuestionIndex"
                :validations="$v.formData.surveyQuestion.$each[nonNpsQuestionIndex + npsQuestions.length]"
                @updateQuestion="updateQuestion"
                @removeQuestion="removeQuestion"
                @checkInitValue="checkInitValue"
              />
            </li>
          </ul>
          <div class="questions-actions">
            <Action
              :text="$t('global:form.management.survey.questions.add')"
              type="button"
              flat
              primary
              @click="addQuestion"
            />
          </div>
        </form>
      </div>
    </div>
    <Pagination
      class="center"
      :active-first-last="false"
      :active-page="pagination.page"
      :page-count="pagination.lastPage"
      @previousPage="prevPage"
    />
  </div>
</template>

<style src="@/assets/styles/themes/default/management.css"></style>
